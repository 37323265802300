import React, { useState } from "react";
import styled from "@emotion/styled";
import tw from "twin.macro";

import addToMailchimp from "gatsby-plugin-mailchimp";
import { useForm } from "react-hook-form";
import Button from "../Button";
import Transitions from "../Transitions";
import Anchor from "../Anchor";
import Link from "../Link";


const SubscribeMailchimp: React.FC<HTMLFormElement> = ({ props }) => {
    const [subscribed, setSubscribed] = useState(false);
    const {
        register,
        handleSubmit,
        setError,
        errors,
        reset,
        formState: { isSubmitting }
    } = useForm();

    const required = 'Ezt a mezőt kötelező kitölteni';

    const onSubmit = (data, e) => {
        let { email, ...fields } = data
        fields['PATHNAME'] = (typeof window !== `undefined`) ? window.location.pathname : '/';

        addToMailchimp(email, fields)
        .then(data => {
            console.log(data);
            if (data.result === "error") {
                throw data;
            }

            setSubscribed(true);

            setTimeout(() => {
                setSubscribed(false);
                reset();
            }, 6000);
        })
        .catch(error => {
            setError('submit', error.msg);
        });
    };

  return (
    <FormElement onSubmit={handleSubmit(onSubmit)} {...props}>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200}>
            <InputContainer third>
                <FormLabel htmlFor="mc-fname">
                    Név
                </FormLabel>
                <FormInput
                    id="mc-fname"
                    type="text"
                    name="FNAME"
                    disabled={subscribed || isSubmitting}
                    error={errors.FNAME}
                    ref={register({ required , maxLength: 100 })} />
                {errors.FNAME && <FormAlert error>{errors.FNAME.message}</FormAlert>}
            </InputContainer>
            <InputContainer third>
                <FormLabel htmlFor="mc-email">
                    Email
                </FormLabel>
                <FormInput
                    id="mc-email" 
                    type="email"
                    name="email"
                    disabled={subscribed || isSubmitting}
                    error={errors.email}
                    ref={register({
                        required,
                        pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    })} />
                {errors.email && <FormAlert error>{errors.email.message}</FormAlert>}
            </InputContainer>
            <InputContainer third flex>
                <FormButton
                    type="submit"
                    error={errors.submit}
                    subscribed={subscribed}
                    disabled={subscribed || isSubmitting}
                >
                    {subscribed ? <CheckMarkIcon fill={'#fff'} /> : "Feliratkozás"}
                </FormButton>
                {errors && errors.submit && <FormAlert>{errors.submit.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
        <Transitions.JS.FadeInZoomGroup as={FormLine} delay={200} tw="-mt-2">
            <InputContainer tw="pb-2">
                <FormInput
                    checkbox
                    type="checkbox"
                    name="accept" 
                    id="mc-accept"
                    value="1" 
                    disabled={subscribed || isSubmitting}
                    error={errors.accept}
                    ref={register({ required })} />
                <FormLabel checkbox htmlFor="mc-accept">
                    Elfogadom az <Anchor as={Link} to="/adatvedelem" style={{color: "#fff"}}>adatvédelmi szabályzatot.</Anchor>
                </FormLabel>
                {errors.accept && <FormAlert error>{errors.accept.message}</FormAlert>}
            </InputContainer>
        </Transitions.JS.FadeInZoomGroup>
    </FormElement>
    );
};

export default SubscribeMailchimp;


const FormElement = styled.form`
    ${tw`w-full max-w-3xl mx-auto mt-8`};
`;

const FormLine = styled.div`
    ${tw`flex flex-wrap mb-6 -mx-3 last:mb-2`};
`;

const InputContainer = styled.div<{half?: boolean, third?: boolean, flex?: boolean}>`
    ${tw`relative w-full px-3`};

    ${p => p.half && tw`flex-grow w-full px-3 mb-6 md:w-1/2 md:mb-0`};
    ${p => p.third && tw`flex-grow w-full px-3 mb-6 md:w-1/3 md:mb-0`}
    ${p => p.flex && tw`self-center flex-1`};
`;

const FormLabel = styled.label<{checkbox?: boolean}>`
    ${tw`block mb-2 text-xs font-bold tracking-wide text-green-100 uppercase`};
    ${p => p.checkbox && tw`inline ml-3`};
`;

const FormInput = styled.input<{error?: boolean; checkbox?: boolean}>`
    ${p => !p.checkbox && tw`block w-full px-4 py-3 mb-3 leading-tight text-gray-800 bg-gray-200 border border-gray-500 rounded appearance-none focus:outline-none focus:bg-white`};

    ${p => p.error && tw`border`};
    ${p => p.error && `border-color: #750102`};
    ${p => p.checkbox && tw`inline py-3 px-3 border border-gray-200`};
`;

const FormAlert = styled.p<{error?: boolean}>`
    ${tw`absolute text-xs italic text-gray-100 -bottom-3`};
    ${p => p.error && `color: #750102`};
`;

const FormButton = styled(Button)<{error?: boolean; subscribed?: boolean}>`
    ${tw`flex items-center justify-center md:mt-3`};
    ${p => p.subscribed && tw`cursor-not-allowed`};
    ${p => p.subscribed && `&:hover { background-color: ${p.theme.colors.secondaryAccent};}`};
`;

const CheckMarkIcon = ({ fill = '#08080B' }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00016 16.1698L4.83016 11.9998L3.41016 13.4098L9.00016 18.9998L21.0002 6.99984L19.5902 5.58984L9.00016 16.1698Z"
      fill={fill}
    />
  </svg>
);