import React from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { keyframes } from '@emotion/core';

interface IWaveSection {
    animated?: boolean;
    invertedBackground?: boolean;
}

interface IWave {
    icon?: React.ReactNode;
    waveContext?: IWaveSection;
    top?: boolean;
    bottom?: boolean;
} 

/**
 * Usage:
 * @example
 * import Icon from '@images/icons/icon.svg';
 * <Wave icon={Icon} waveContext={{ animated: false, invertedBackground: false }} top className="custom-class" />
*/
const Wave: React.FC<IWave> = ({ icon, waveContext, ...props }) => {
    const Icon = (icon) ? icon : require('@images/svg/wave.inline.svg');
    return (
    <WaveContainer { ...props }>
        <WaveSection {...waveContext}>
            <Icon />
        </WaveSection>
    </WaveContainer>
    );
};

const wave = keyframes`
    from {
        margin-left: -1000px;
    }
    to {
        margin-left: 0;
    }
`;

const WaveContainer = styled.div<{ top?: boolean, bottom?: boolean }>`
    ${tw`absolute w-full z-1`};
    
    ${p => p.top && tw`inset-x-0 -top-40 md:-top-48`};
    
    ${p => p.bottom && tw`inset-x-0 -bottom-40 md:-bottom-48`};
    ${p => p.bottom && `transform: matrix(-1, 0, 0, -1, 0, 0);`};

`;

const WaveSection = styled.div<IWaveSection>`
    ${tw`relative block w-full`};

    & svg {
        ${tw`absolute block w-full h-40 md:h-48`};

        & path {
            ${tw`w-full`};
            fill: ${p => p.theme.colors.accent};
            
            ${p => p.animated && `
                animation: ${wave} 25s linear infinite;
            `};
            ${p => p.invertedBackground && `
                fill: ${p.theme.colors.background};
            `};
        }
    }
`;

export default Wave;